<template>
    <div>
        <strong>Shipping Data Upload</strong>
        <br>

        <div v-if="appState.fileUploadContextData.metadata">
            <p>Attention: This will update {{ appState.fileUploadContextData.metadata.length }} trackers!</p>
            Double check the tracker numbers, dates, and costs.
            <br>
            <br>
            <table class="table-responsive">
                <tbody>
                    <tr>
                        <th>Tracker #</th>
                        <th>Delivery Date</th>
                        <th>Amount Due</th>
                    </tr>
                    <tr v-for="row in appState.fileUploadContextData.metadata" v-bind:key="row.tracker_id">
                        <td>{{ row.tracker_id }}</td>
                        <td>{{ row.receive_ship }}</td>
                        <td>{{ row.freight_cost }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";

    export default {
        name: "ShippingData",
        data() {
            return {
                state: fileUploadStore.state,
                appState: appStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            fileUploadStore.loadMetadata();
            this.state.referenceID = 1;
        }
    }
</script>

<style scoped>

</style>